import { createReducer } from '@reduxjs/toolkit';
import { DeleteUserBotSuccess } from '@store/user-bots/features/delete-user-bot/delete-user-bot-success';
import { GetCurrentUserBot } from '@store/user-bots/features/get-current-user-bot/get-current-user-bot';
import { GetCurrentUserBotResult } from '@store/user-bots/features/get-current-user-bot/get-current-user-bot-result';
import { GetUserBotsSuccess } from '@store/user-bots/features/get-user-bots/get-user-bots-success';
import { SetSelectedUserBot } from '@store/user-bots/features/set-selected-user-bot/set-selected-user-bot';

import { IUserBotsState } from './types';

const initialState: IUserBotsState = {
  userBots: [],
  currentUserBot: null,
  selectedUserBot: null,
  requests: {
    getCurrentUserBotPending: false,
  },
};

const reducer = createReducer<IUserBotsState>(initialState, (builder) =>
  builder
    .addCase(GetUserBotsSuccess.action, GetUserBotsSuccess.reducer)
    .addCase(DeleteUserBotSuccess.action, DeleteUserBotSuccess.reducer)
    .addCase(GetCurrentUserBotResult.action, GetCurrentUserBotResult.reducer)
    .addCase(SetSelectedUserBot.action, SetSelectedUserBot.reducer)
    .addCase(GetCurrentUserBot.action, GetCurrentUserBot.reducer),
);

export default reducer;
