import React from 'react';

import Logo from '@assets/img/lp-logo.png';
import { selectCurrentBotLoadingStatus } from '@store/user-bots/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BotAvatarService } from '../../services/bot-avatar-service';
import { CountShowingAdultBannerService } from '../../services/count-showing-adult-banner-service';

import styles from './LogoScreen.module.scss';

const botAvatarLink = new BotAvatarService();
const countService = new CountShowingAdultBannerService();

const LogoScreen = () => {
  const { t } = useTranslation();
  const isBotLoading = useSelector(selectCurrentBotLoadingStatus);
  const brandLogo = botAvatarLink.avatar || Logo;

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {isBotLoading ? null : (
          <img src={brandLogo} alt='LingoPraxis Bot' className={styles.image} />
        )}
      </div>
      {countService.count < 2 && <p className={styles.banner}>{t('splash.banner')}</p>}
    </div>
  );
};

export default LogoScreen;
